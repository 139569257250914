import get from 'lodash.get';
import { cloneDeep } from 'lodash';

const LATEST_PRESS_RELEASE_PAGE = '/insights/press-and-news/press-releases';

const SampleListItem = {
  nodeType: 'list-item',
  content: [
    {
      nodeType: 'paragraph',
      content: [
        {
          nodeType: 'entry-hyperlink',
          content: [
            {
              nodeType: 'text',
              value: 'ITEM_TEXT_HERE',
              marks: [],
              data: {},
            },
          ],
          data: {
            target: {
              sys: {
                contentType: {
                  sys: {
                    id: 'page',
                  },
                },
              },
              fields: {
                slug: {
                  'en-US': 'ITEM_SLUG_HERE',
                },
              },
            },
          },
        },
      ],
      data: {},
    },
  ],
  data: {},
};

const usePressReleases = (pageContent, allPages, pagePath) => {
  const content = { ...pageContent };
  const contentArray = get(content, 'json.content');

  const pressReleasePages = get(allPages, 'edges', []).map(p => p.node);

  if (Array.isArray(contentArray)) {
    contentArray.forEach(item => {
      // Upate the latest 4 press releases on "Press and News" page
      const { autoList, items } = get(item, 'data.target.fields', {});
      if (autoList && autoList['en-US'] === 'Press Releases') {
        items['en-US'] = pressReleasePages
          .map(page => [page.title, page.date, `/${page.slug}`])
          .slice(0, 4);
      }

      // Update the press releases on "Latest Press Releas" page.
      if (pagePath === LATEST_PRESS_RELEASE_PAGE && item.nodeType === 'unordered-list') {
        item.content = pressReleasePages.map(page => {
          const clonedItem = cloneDeep(SampleListItem);
          clonedItem.content[0].content[0].content[0].value = page?.title;
          clonedItem.content[0].content[0].data.target.fields.slug['en-US'] = page?.slug;
          return clonedItem;
        });
      }
    });
  }

  return content;
};

export default usePressReleases;
